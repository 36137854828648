.custom-header {
  padding: 0 2.5%;
  background-color: $snlc-white;
  //   clip-path: polygon(0 0, 100% 0, 100% calc(100% - 50px), calc(100% - 50px) 100%, 0 100%);
  width: 100%;

  @media all and (max-width: 768px) {
    padding: 0;
  }

  p {
    text-align: center;
  }

  .toggle,
  [id^="drop"] {
    display: none;
  }

  nav {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 999;
  }

  #logo {
    display: block;
    padding: 0 30px;
    float: left;
    font-size: 20px;
    line-height: 60px;
  }

  nav:after {
    content: "";
    display: table;
    clear: both;
  }

  nav ul {
    float: left;
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
  }

  nav ul li {
    margin: 0px;
    display: inline-block;
    float: left;

    &:hover {
      background-color: $snlc-white;
    }
  }

  nav a {
    display: block;
    padding: 0 25px;
    color: $snlc-black;
    line-height: 48px;
    text-decoration: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.28px;
    text-transform: capitalize;
  }

  nav a:hover {
    background-color: $snlc-black;
    color: $snlc-white;
    // font-weight: 700;
  }

  nav ul ul {
    display: none;
    position: absolute;
    top: 48px;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.25);
    width: fit-content;
    padding: 4px 0;

    li {
      a {
        line-height: 1;
        padding: 12px 26px;
        font-size: 0.85rem !important;
      }
    }
  }

  nav ul li:hover > ul {
    display: block;
    background-color: $snlc-white;
    max-height: max-content;
    // overflow: auto;
    z-index: 999;
    @media all and (max-width: 768px) {
      width: 100%;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #000000, #0a0a0a);
      /* Gradient color */
      border-radius: 10px;
      border: 2px solid transparent;
      /* Padding around the thumb */
      background-clip: content-box;
      /* Prevents background from overlapping border */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(180deg, #388e3c, #66bb6a);
    }

    &::-webkit-scrollbar-thumb:active {
      background: linear-gradient(180deg, #2e7d32, #4caf50);
    }

    &::-webkit-scrollbar-corner {
      background: #f1f1f1;
      /* Color of the corner where horizontal and vertical scrollbars meet */
    }
  }

  nav ul ul li {
    width: auto;
    max-width: 300px;
    float: none;
    display: list-item;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    @media all and (max-width: 768px) {
      max-width: 100%;
    }

    &:hover {
      > ul {
        background-color: transparent;
        box-shadow: none;
        @media all and (max-width: 768px) {
          position: relative;
          left: 0;
        }
      }
    }

    &:last-child {
      border: none;
    }
  }

  nav ul ul ul {
    background-color: $snlc-white;
    position: absolute;
    right: -100%;
    width: 100%;
    margin-top: -48px;
  }

  nav ul ul ul li {
    background-color: $snlc-white;
    display: block;
    width: 100%;
  }

  nav ul ul li a {
    &:after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cg opacity='0.8'%3E%3Cpath d='M7.76342 5.75131L12 10L7.75131 14.2366' stroke='%230A0A0A' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
    }
  }

  nav ul ul li {
    &:hover {
      a {
        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M7.76342 5.75131L12 10L7.75131 14.2366' stroke='%23FFFFFA' stroke-width='2'/%3E%3C/svg%3E");
        }
      }
    }
  }

  nav ul ul li {
    &:hover {
      a {
        &:only-child {
          &::after {
            content: "";
          }
        }
      }
    }
  }

  li > a:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M12.2159 7.73079L8 12L3.73079 7.7841' stroke='%230A0A0A' stroke-width='2'/%3E%3C/svg%3E");
    @media all and (max-width: 768px) {
      float: inline-end;
    }
  }

  // li {
  //   &:hover {
  //     a {
  //       &::after {
  //         content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M12.2159 7.73079L8 12L3.73079 7.7841' stroke='%23FFFFFA' stroke-width='2'/%3E%3C/svg%3E");
  //       }
  //     }
  //   }
  // }

  li > a:only-child:after {
    content: "";
  }

  /* Media Queries
    --------------------------------------------- */

  @media all and (max-width: 768px) {
    #logo {
      display: block;
      padding: 0;
      width: 100%;
      text-align: center;
      float: none;
    }

    nav {
      margin: 0;
    }

    .toggle + a,
    .menu {
      display: none;
    }

    .toggle {
      display: block;
      background-color: $snlc-black;
      padding: 0 20px;
      color: $snlc-white;
      font-size: 22px;
      font-weight: 500;
      line-height: 60px;
      text-decoration: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 80px;
        height: 80px;
        padding: 8px;
      }
    }

    .toggle:hover {
      background-color: $snlc-white;
    }

    [id^="drop"]:checked + ul {
      display: block;
    }

    nav ul li {
      display: block;
      width: 100%;
    }

    nav ul ul .toggle,
    nav ul ul a {
      padding: 0 32px;
    }

    nav ul ul ul a {
      padding: 0 80px;
    }

    nav a:hover,
    nav ul ul ul a {
      background-color: #fff;
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.28px;
      text-transform: capitalize;
      line-height: 1;
      padding: 18px 32px;
    }

    nav ul li ul li .toggle,
    nav ul ul a {
      background-color: $snlc-white;
      color: $snlc-black;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.28px;
      text-transform: capitalize;
      &:after {
        content: "\f078"; /* Unicode for FontAwesome chevron-down icon */
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        margin-left: 5px; /* Adjust space between text and icon */
      }
    }

    nav ul ul {
      float: none;
      position: static;
      color: #ffffff;
    }

    nav ul ul li:hover > ul,
    nav ul li:hover > ul {
      // display: none;
    }

    nav ul ul li {
      display: block;
      width: 100%;
    }

    nav ul ul ul li {
      position: static;
    }
  }

  @media all and (max-width: 330px) {
    nav ul li {
      display: block;
      width: 94%;
    }
  }
}

.logoSection {
  background: linear-gradient(
    93deg,
    rgba(53, 187, 237, 0.6) 14.19%,
    rgba(255, 255, 250, 0.6) 132.09%
  );
  padding: 32px 44px 40px 44px;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 180px), calc(100% - 180px) 100%, 0 100%);
}

.topMenulinks {
  color: $snlc-black;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding: 25.5px 32px 25.5px 64px;
  clip-path: polygon(12% 0, 100% 0, 100% 100%, 0% 100%);
  background-color: #35bbed60;
  @media all and (min-width: 1600px) {
    clip-path: polygon(9% 0, 100% 0, 100% 100%, 0% 100%);
  }

  a {
    color: $snlc-black;
    text-align: center;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    &:hover {
      font-weight: 700 !important;
    }
  }

  @media all and (max-width: 768px) {
    clip-path: none;
    padding: 16px;
    width: 100%;
    margin: 0;

    a {
      padding: 0 8px;
    }
  }
}

.top-cta-links {
  a {
    color: $snlc-black;
    font-family: Arial;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    justify-content: end;
  }
}

.admissionCta {
  background-color: $snlc-black;
  border: none;
  padding: 16px 50px;
  cursor: pointer;
  position: relative;
  transform: skew(-43deg);
  transition: background-color 0.3s;
  margin-left: -20%;
  @media all and (min-width: 1600px) {
    margin-left: -13%;
  }

  span {
    color: $snlc-white;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    display: block;
    transform: skew(42deg);
    text-align: left;

    a {
      color: $snlc-white;
    }
  }

  @media all and (max-width: 768px) {
    transform: none;
    margin-left: 0;
    padding: 16px 8px;
    background-color: $snlc-white;

    span {
      transform: none;
      font-size: 14px !important;
      color: $snlc-black;

      a {
        color: $snlc-black;
      }
    }
  }
}

.back-to-top {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1000;

  button {
    background-color: $snlc-black;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, transform 0.3s;
    opacity: 0.85;
    height: 40px;
    width: 40px;
    outline: 1px solid $snlc-white;
    outline-offset: -3px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
}

.menu-auto-scroll {
  max-height: 450px;
  overflow-y: auto;
}
