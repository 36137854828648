.recentEvents,
#testimonial,
.congrats-slider-v2,
.gallery-container-v2 {
  position: relative;
  .header-area {
    display: flex;
    align-items: center;
    h2 {
      color: $snlc-black;
      font-family: Georgia;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      word-wrap: normal;
    }
  }
  .MuiPaper-root {
    border-radius: 0 !important;
    padding: 0 !important;
    .events-img-transition {
      border-radius: 0 !important;
      height: 379px;
      object-fit: cover;
      &:hover {
        opacity: 1;
        transform: none !important;
      }
    }
  }
  .MuiBox-root {
    background-color: transparent !important;
  }
  .slick-slider {
    background-color: transparent !important;
    box-shadow: none !important;
    .slick-prev {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      opacity: 0.45;
      right: -6% !important;
      left: unset !important;
      top: 35%;
      &::before {
        content: url("../../images/v2/nav-icon.svg") !important;
      }
      &:hover {
        opacity: 1;
      }
    }
    .slick-next {
      opacity: 0.45;
      right: -3% !important;
      top: 20% !important;
      &::before {
        content: url("../../images/v2/nav-icon.svg") !important;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .event-list {
    .MuiBox-root {
      &:has(h6) {
        height: 120px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        h6 {
          line-height: 12px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 15em;
          line-height: 1.5;
          text-align: center;
        }
      }
    }
    .events-date {
      background-color: #eee !important;
      border-radius: 0 !important;
      top: 0 !important;
      right: 0 !important;
      font-size: 12px !important;
      font-weight: 600 !important;
    }
  }
}
#testimonial {
  padding: 1% 0 1% 0;
  .heading-section {
    border-bottom: 1px solid #f6e763;
    padding-bottom: 12px;
  }
  .content-section {
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 1080px) {
      padding: 24px;
    }
  }
  h5 {
    color: #000;
    font-family: Georgia;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  h6 {
    color: $snlc-black;
    text-align: justify;
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 133.333% */
  }
  p {
    color: $snlc-black;
    text-align: justify;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .sign {
    max-width: 50%;
  }
}

.gallery-slider {
  // padding: 2.5% 0;
  .header-area {
    display: flex;
    align-items: center;
    h2 {
      color: $snlc-black;
      font-family: Georgia;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .congrats-slider {
    /* background: linear-gradient(360deg, transparent 1%, rgba(252, 201, 7, 0.65) 50%) !important; */
    border-radius: 0 !important;
    opacity: 1;
    transition: opacity 1s, transform 1s;
    outline: none !important;
    width: 300px;
    height: 380px;
    /* object-fit: cover; */
    margin: 15px;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 0.25);
    padding: 5px;
    background-color: #fff;
    &:hover {
      opacity: 0.9;
      transform: none !important;
      background-color: $snlc-yellow;
    }
  }
}
.gallery-container {
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: start;
  width: 85%;
}

.gallery-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
}

.gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;
}
.card-container {
  padding: 15% 0;
  width: 100%;
  overflow: hidden;
  padding-left: 10%;
  @media only screen and (max-width: 1600px) {
    padding: 18% 0;
  }
}
.card {
  width: 300px;
  height: 450px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  position: absolute;
  filter: grayscale(1);
  &:hover {
    filter: none;
    cursor: all-scroll;
  }
}

.card:nth-child(1) {
  left: -120px;
  z-index: 1;
}

.card:nth-child(2) {
  left: -40px;
  z-index: 2;
}

.card:nth-child(3) {
  left: 80px;
  z-index: 3;
  transform: scale(1.1);
  filter: none;
}

.card:nth-child(4) {
  left: 250px;
  z-index: 2;
}

.card:nth-child(5) {
  left: 500px;
  z-index: 1;
}
.card:nth-child(6) {
  left: 700px;
  z-index: 1;
}
.card:nth-child(7) {
  left: 850px;
  z-index: 1;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card:hover {
  transform: scale(1.2);
  z-index: 10;
}

// End Gallery
.congrats-slider-v2 {
  padding: 2.5% 0;
  img {
    border-radius: 0 !important;
    outline: none !important;
    &:hover {
      transform: none !important;
      cursor: pointer;
    }
  }
}
.courses-container-v2 {
  position: relative;
  // padding: 5% 0;
  .heading-container {
    position: absolute;
    width: 55%;
    height: 35%;
    @media only screen and (max-width: 1080px) {
      position: relative;
      width: 100%;
      h2 {
        width: 100%;
      }
    }
    background-color: #eeedea;
    h2 {
      color: #0a0a0a;
      font-family: Georgia;
      font-size: 48px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      padding: 5%;
      width: 60%;
      word-wrap: normal;
      @media only screen and (max-width: 1080px) {
        width: 100%;
        font-size: 32px;
      }
    }
  }

  .courses-card-v2 {
    clip-path: polygon(45% 0, 100% 0, 100% 71%, 36% 71%, 22% 100%, 0 100%, 0 34%, 29% 34%);
    background-image: url("../../images//v2/snlc-courses.webp");
    padding: 10% 0 15% 30%;
    color: $snlc-yellow;
    background-position: bottom;
    background-size: contain;
    position: relative;
    @media only screen and (max-width: 1080px) {
      clip-path: none;
      padding: 5% 0 5% 5%;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7); /* Adjust overlay color and opacity */
      z-index: 1;
    }
    .row {
      margin-left: 4px;
      .col-md-4 {
        flex: 0 0 auto;
        width: 30%;
        @media only screen and (max-width: 1080px) {
          width: 100%;
        }
      }
    }
    .trapezoid {
      background-color: rgba(255, 255, 250, 0.2);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      backdrop-filter: blur(2px);
      border: 1px solid rgba(255, 255, 250, 0.4);
      padding: 5% 0;
      transform: skew(-45deg); /* skew the element to form a parallelogram */
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 999;
      // transition: border 0.3s ease, border-radius 0.3s ease;
      min-height: 120px;
      text-align: center;
      @media only screen and (max-width: 1080px) {
        width: 70%;
        margin: 16px auto;
        transform: skew(-45deg) scale(0.85); /* skew the element to form a parallelogram */
      }
      .inner-content {
        transform: skew(45deg);
        padding: 0 15%;
        h3 {
          color: #fffffa;
          font-family: Georgia;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 83.333% */
          text-transform: uppercase;
          @media only screen and (max-width: 1080px) {
            font-size: 18px;
          }
        }
        h4 {
          color: #fffffa;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 125% */
          text-transform: uppercase;
          opacity: 1;
          @media only screen and (max-width: 1080px) {
            font-size: 14px;
          }
        }
        h5 {
          color: #fffffa;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          opacity: 1;
        }
      }
      &:hover {
        background-color: rgba(10, 10, 10, 0.4);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        backdrop-filter: blur(5.46999979019165px);
        border: 1px solid #0a0a0a;
        cursor: pointer;
        h3,
        h4,
        h5 {
          color: #fffffa;
        }
      }
    }
  }
  .quick-links {
    position: relative;
    margin-top: -10%;
    @media only screen and (max-width: 1080px) {
      margin-top: 0;
      .col-md-3 {
        width: 45%;
        margin-top: 12px;
      }
    }
    // padding-bottom: 10%;
    a {
      display: flex;
      flex-direction: column;
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin: 0 auto;
      }
      svg {
        width: 100px;
        height: 100px;
        object-fit: contain;
        margin: 0 auto;
        &.icon ~ path:hover {
          stroke: #35bbed;
        }
      }
      span {
        color: $snlc-blue;
        text-align: center;
        font-family: Arial;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        opacity: 1;
        padding-top: 12px;
      }
      &:hover {
        span {
          color: $snlc-black;
          text-align: center;
          font-family: Arial;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: capitalize;
          opacity: 1;
        }
        svg path:hover {
          stroke: #35bbed;
        }
      }
    }
  }
}
.right-side-top {
  float: right;
  width: 55%;
  padding: 1%;
}
.upcoming-events-box {
  background: #fff;
  box-shadow: -2.415px 3.22px 12px 0px rgba(10, 10, 10, 0.1);
  padding: 18px 24px 48px 18px;
  position: relative;
  z-index: 999;
  min-height: 400px;
  .svg-area {
    position: absolute;
    z-index: 0;
    right: 0;
    bottom: 5%;
    svg {
      width: auto;
      height: 400px;
    }
  }
  h2 {
    color: #0a0a0a;
    font-family: Georgia;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  img {
    z-index: 999;
    position: relative;
    height: 380px;
    object-fit: contain;
  }
}
.svg-line {
  display: block;
}
/* Media query for desktop */
@media (min-width: 1024px) {
  #gallery-desktop {
    display: block;
  }
  #gallery-mobile {
    display: none;
  }
}
/* Media query for mobile */
@media (max-width: 1024px) {
  #gallery-desktop {
    display: none;
  }
  #gallery-mobile {
    display: block;
    padding: 48px 0;
    .slick-slider {
      img {
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        max-height: 550px;
        object-fit: contain;
      }
      .slick-dots {
        bottom: -35px;
        width: 100%;
        margin: 0;
        list-style: none;
        text-align: center;
      }
    }
  }
}
.logo-slider-container {
  padding: 0 5% 5% 5%;
  .slick-slider {
    .slick-slide {
      padding: 0 30px;

      .logo-box {
        background: rgba(10, 10, 10, 0.01);
        stroke-width: 0.552px;
        border: 0.552px solid rgba(10, 10, 10, 0.08);
        overflow: hidden;
        position: relative;
        transform: skew(-20deg);
        padding: 36px;
        img {
          width: 100%;
          height: 80px;
          object-fit: contain;
          transform: skew(20deg);
          filter: grayscale(1);
        }
        &:hover {
          border: 1px solid rgba(10, 10, 10, 0.95);
          background-color: #fff;
          img {
            width: 100%;
            height: 80px;
            object-fit: contain;
            transform: skew(20deg);
            filter: grayscale(0);
          }
        }
        @media (max-width: 1024px) {
          transform: skew(-20deg) scale(0.75);
        }
      }
    }
    & .slick-list {
      margin: 0 -10px; /* Negative value to counteract the slide margins */
    }
  }
}

.image-card {
  position: relative;
  height: 520px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 25px;
}

.image-card img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    0deg,
    #0a0a0a 0%,
    rgba(10, 10, 10, 0.66) 51.19%,
    rgba(10, 10, 10, 0) 100%
  );
  backdrop-filter: blur(1px);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.image-card:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  position: absolute;
  bottom: 0;
  left: 10px;
  padding: 5px;
}

.text h2 {
  margin: 0;
  color: #fff;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  padding-bottom: 8px;
}
.text p {
  color: #fff !important;
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  opacity: 0.6;
}
#testimonial {
  .slick-slider {
    .slick-slide {
      padding: 0 10px;
    }
    .slick-list {
      margin: 0 -10px; /* Negative value to counteract the slide margins */
    }
  }
}
@media only screen and (max-width: 1080px) {
  .snlc_player {
    height: auto !important;
  }
}
.events-date {
  z-index: 8 !important;
}
.heading-container1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 32px;
  .heading {
    color: #0a0a0a;
    font-family: Georgia;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding-right: 18px;
  }
  .yellow-line {
    width: 100%;
    min-width: 75%;
    height: 1.5px;
    background-color: #f7e763;
  }
}
