@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");

#profile-container h1 {
  color: rgba(10, 10, 10, 0.4);
  text-transform: uppercase;
}
a:focus-visible {
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.about-text {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
  color: rgba(10, 10, 10, 0.7) !important;
}

.main-head {
  color: rgba(10, 10, 10, 0.8) !important;
  background: linear-gradient(180deg, transparent 50%, rgba(3, 170, 233, 0.35) 50%);
  width: 100%;
  display: inline;
  text-transform: uppercase;
}

.prayer-left {
  position: absolute;
  bottom: 0;
  left: -2%;
}

.prayer-right {
  position: absolute;
  bottom: 0;
  right: -2%;
}

.yellow-right {
  position: absolute;
  bottom: 55%;
  right: -5.7%;
  opacity: 0.5;
}

body {
  overflow-x: hidden;
}

.quality-policy {
  background: linear-gradient(94.69deg, #ff7a1d -14.48%, #faf20a 152.03%) !important;
  padding: 30px;
  color: #ffffff;
}

.quality-policy h1 {
  color: rgba(255, 255, 255, 0.4) !important;
}

.css-40yl7s {
  width: 70% !important;
}

.course-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: none !important;
  color: #fff !important;
  font-weight: 300;
  text-align: justify;
  background: url("../images/law-bg.jpg");
  background-size: cover;
  height: 160px;
}

.questions-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: none !important;
  color: #fff !important;
  font-weight: 300;
  text-align: justify;
  background: url("../images/questions-bg.jpg");
  background-size: cover;
  border-radius: 25px;
}

.course-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.css-188flkz-MuiTablePagination-displayedRows {
  margin-top: 1em !important;
}

.css-p33a0u-MuiTablePagination-selectLabel {
  margin-top: 1em !important;
}

body {
  font-family: "Open Sans", sans-serif !important;
}

/* .css-z923cu-MuiTypography-root {
  font-size: 0.82rem !important;
}
.css-159slit {
  margin-left: 0px !important;
}
.css-x5zaln-MuiIcon-root {
  width: 0px !important;
} */
.custom-navbar {
  font-size: 0.82rem !important;
  margin-left: 0px !important;
}

.category-card {
  min-height: 150px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid #03aae9;
  /* border-radius: 40px; */
  padding: 10px 20px;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* background: linear-gradient(180deg, #fcc907 -1.52%, rgba(252, 201, 7, 0) 131.68%); */
  background-color: #ffffff;
  opacity: 0.8;
}

.category-card:hover {
  border: 1px solid #03aae9;
  box-shadow: 0px 10px 32px -14px #03aae9;
  opacity: 1;
}

/* .category-card:before,
.category-card:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
} */
.category-head {
  color: #0a0a0a;

  opacity: 0.8;
  text-align: center !important;
  font-size: 18px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
  min-height: 50px;
  display: flex;
  align-items: center;
}

/* .category-card:before,
.category-card:after {
  bottom: 15px !important;
  left: 15px !important;
} */

/* .category-card:before {
  border-top: 2px solid rgba(10, 10, 10, 0.1);
  border-right: 2px solid rgba(10, 10, 10, 0.1);
  border-bottom: 2px solid rgba(10, 10, 10, 0.1) !important;
  border-left: 2px solid rgba(10, 10, 10, 0.1) !important;
}
.category-card:hover:before {
  border-top: 2px solid rgba(10, 10, 10, 1);
  border-right: 2px solid rgba(10, 10, 10, 1);
  border-bottom: 2px solid rgba(10, 10, 10, 1) !important;
  border-left: 2px solid rgba(10, 10, 10, 1) !important;
  cursor: pointer;
  z-index: 999;
}
.category-card:after {
  border-bottom: 2px solid rgba(10, 10, 10, 0.1) !important;
  border-left: 2px solid rgba(10, 10, 10, 0.1) !important;
  z-index: -1;
} */
video {
  object-fit: cover !important;
  /* background: linear-gradient(180deg, #fcc907 -1.52%, rgba(252, 201, 7, 0) 131.68%); */
  /* border-radius: 20px; */
  /* padding: 20px; */
  height: 320px !important;
  border: 1px solid #3c3939;
}
.react-player__preview {
  object-fit: cover !important;
  border-radius: 20px;
  height: 450px;
  /* border: 5px solid #e7ebf0; */
}
@media only screen and (max-width: 600px) {
  video {
    object-fit: fill !important;
    /* background: linear-gradient(180deg, #fcc907 -1.52%, rgba(252, 201, 7, 0) 131.68%);vide */
    /* padding: 15px; */
    /* border-radius: 10px; */
    height: 240px !important;
  }
}

/* .player-wrapper {
  position: relative;
  object-fit: cover !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
} */
.css-1s13e1c {
  margin: 0 !important;
}

/* .css-1h15ts-MuiPaper-root-MuiCard-root {
  margin: 15px !important;
} */
.testimonialImg {
  z-index: 1000;
  width: 20%;
  right: 5%;
  top: 3% !important;
  position: absolute;
  opacity: 0.05;
}

.testimonials {
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin: 20px;
  border-radius: 50%;
  background: linear-gradient(180deg, #fcc907 -1.52%, rgba(252, 201, 7, 0) 131.68%);
  padding: 10px;
}

.testimonials-card {
  padding: 10px;
  margin-right: 10px !important;
  background: #ffffff;
  /* border: 2px solid rgba(10, 10, 10, 0.2) !important; */
  box-sizing: border-box;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
  border: none !important;
}

.testimonials-card h4 {
  text-align: center;
  font-weight: bold !important;
  font-size: 20px !important;
  /* min-height: 60px; */
}

.testimonials-card p {
  font-size: 14px;
  text-align: center;
  font-style: italic;
  font-weight: bold !important;
  min-height: 100px !important;
}

.testimonials-card h6 {
  font-size: 16px;
  text-align: center;
  font-weight: 400;
}

/* .testimonials-card .card-body {
  height: 250px;
  overflow-y: hidden;
  text-overflow: ellipsis;
} */
.css-1dnyxxi {
  min-height: 30vh !important;
}

.css-lkj5jl {
  padding-bottom: 15px !important;
  padding-top: 15px !important;
}

.css-1dth4jw {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  margin-bottom: 8px !important;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

.MuiTablePagination-displayedRows,
.css-1xx1nvo-MuiTablePagination-displayedRows {
  margin-bottom: 0rem !important;
}

.MuiTablePagination-selectLabel,
.css-rhfgmz-MuiTablePagination-selectLabel {
  margin-bottom: 0rem !important;
}

.club-slider {
  width: 300px;
  height: 300px;
  object-fit: fill;
}

.achivements {
  /* background: linear-gradient(104deg, #fcc907 4.81%, rgba(252, 201, 7, 0) 84.24%);
  padding: 15px; */
  border: 10px solid #f0f2f5;
  border-radius: 15px;
  height: 450px;
  width: 350px;
  object-fit: cover;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

.achivements:hover {
  opacity: 0.6;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

/* .achivements-text {
  background: linear-gradient(135deg, transparent 5%, rgba(252, 201, 7, 0.65) 50%) !important;
  padding: 20px !important;
  text-transform: capitalize !important;
  margin: 0 21px !important;
} */
.library-slider {
  background: linear-gradient(235deg, transparent 5%, rgba(252, 201, 7, 0.65) 50%) !important;
  padding: 20px;
  border-radius: 10px;
}
.library-slider img {
  aspect-ratio: 7/5;
}

.text-j {
  text-align: justify !important;
  font-size: 1rem !important;
  line-height: 1.985 !important;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  letter-spacing: -0.125px;
  font-family: "Raleway", sans-serif;
  font-weight: 500 !important;
}

.text-number {
  text-align: justify !important;
  font-size: 1rem !important;
  line-height: 1.985 !important;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  -webkit-text-decoration: none;
  text-decoration: none;
  letter-spacing: -0.125px;
  font-weight: 500 !important;
}

.btn-close {
  position: absolute;
  cursor: pointer;
  right: 5%;
  top: 5%;
}

.left-side {
  float: left;
  width: 40%;
  padding: 1%;
}

.left-side1 {
  float: left;
  width: 40%;
  padding: 2%;
  margin-right: 15px;
}

.left-side2 {
  float: left;
  width: 45%;
  padding: 2%;
  margin-right: 15px;
}

.right-side {
  float: right;
  width: 25%;
  padding: 1%;
}

.club-video {
  background: linear-gradient(180deg, transparent 1%, rgba(252, 201, 7, 0.85) 50%) !important;
  padding: 20px;
  border-radius: 10px;
}

.contactMap {
  background: linear-gradient(270deg, transparent 1%, rgba(252, 201, 7, 0.85) 50%) !important;
  padding: 20px;
  border-radius: 10px;
  height: 440px;
}

.heading-bg {
  background: linear-gradient(180deg, transparent 60%, rgba(3, 170, 233, 0.25) 50%);
  width: 100%;
  display: inline;
}
.criteria-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  background-color: #f0f2f5 !important;
  border: none !important;
  min-height: 350px;
  border-radius: 40px;
}
.criteria-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  background-color: #ffffff !important;
}
.naac-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  background-color: #f0f2f5 !important;
  border: none !important;
  min-height: 140px;
  border-radius: 40px;
}
.naac-card:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  background-color: #ffffff !important;
}
.congrats-slider {
  /* background: linear-gradient(360deg, transparent 1%, rgba(252, 201, 7, 0.65) 50%) !important; */
  /* padding: 20px; */
  border-radius: 10px;
  opacity: 1;
  transition: opacity 1s, transform 1s;
  outline: 5px solid rgba(255, 255, 255, 0.3);
  outline-offset: -20px;
  width: 300px;
  height: 380px;
  /* object-fit: cover; */
  margin: 15px;
}

.congrats-slider:hover {
  opacity: 0.9;
  transform: scale3d(1, 1.05, 1);
}

.events-img-transition {
  opacity: 1;
  transition: opacity 1s, transform 1s;
}

.events-img-transition:hover {
  opacity: 0.9;
  transform: scale3d(1, 1.05, 1);
}

.congrats-img {
  position: absolute;
  top: -15%;
  right: 0;
  opacity: 0.15;
}

.events-img1 {
  position: absolute;
  top: 0;
  right: -6%;
  opacity: 0.05;
  width: 8%;
}

.events-img2 {
  position: absolute;
  top: 0;
  left: -6%;
  opacity: 0.05;
  width: 8%;
  transform: rotate(270deg);
}

.events-img3 {
  position: absolute;
  top: 0;
  left: 30%;
  opacity: 0.15;
  width: 6%;
}

.events-img4 {
  position: absolute;
  top: 0;
  right: 30%;
  opacity: 0.15;
  width: 6%;
}

.inner-menu {
  top: 0 !important;
  height: 50px !important;
}

.css-159slit {
  margin-left: 0px !important;
}

/* .loader {
  width: 180px !important;
} */
.staffImg {
  width: 120px !important;
  height: 160px !important;
}

.footer-links {
  color: rgba(253, 202, 8, 1);
  font-size: 16px;
}

.footer-links:hover {
  color: rgba(252, 201, 7, 0.4);
  font-size: 16px;
}
.course-links {
  color: rgba(10, 10, 10, 1) !important;
  font-size: 16px;
}

.course-links:hover {
  color: rgba(10, 10, 10, 0.6);
  font-size: 16px;
}

.events-date {
  background: rgba(255, 255, 255, 0.8) !important;
  color: #1d1d1e !important;
  position: absolute;
  top: 2%;
  z-index: 1000;
  padding: 5px;
  border-radius: 10px;
  right: 2%;
  font-size: 14px;
}

.read-more-btn {
  background-color: #fdca0e !important;
  padding: 5px 10px;
  border-radius: 50px;
  font-size: 10px;
  color: #1d1d1e !important;
  font-weight: bold !important;
}

.gallery-list img {
  height: 300px !important;
  object-fit: cover;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.gallery-list img:hover {
  transform: scale(1.15);
}

.our-team {
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.our-team:before,
.our-team:after {
  content: "";
  width: 130px;
  height: 150px;
  /* background: rgba(0, 0, 0, 0)
    linear-gradient(104deg, rgb(252, 201, 7) 4.81%, rgba(252, 201, 7, 0) 84.24%) repeat scroll 0% 0%; */
  position: absolute;
  z-index: -1;
}

.our-team:before {
  bottom: -20px;
  left: 0;
}

.our-team:after {
  top: -20px;
  right: 0;
}

.our-team .pic {
  margin: 12px;
  position: relative;
  transition: all 0.5s ease 0s;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.our-team .pic img {
  height: 220px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border: 2px solid rgb(252, 201, 7);
}

.our-team:hover .pic {
  border-color: #33343e;
}

.our-team .pic:after {
  content: "";
  width: 100%;
  height: 0;
  background: #33343e;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transform-origin: 0 0 0;
  transition: all 0.5s ease 0s;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}

.our-team:hover .pic:after {
  height: 100%;
  opacity: 0.85;
}

.our-team img {
  width: 100%;
  height: auto;
}

.our-team .team-content {
  width: 100%;
  position: absolute;
  top: -50%;
  left: 0;
  transition: all 0.5s ease 0.2s;
}

.our-team:hover .team-content {
  top: 38%;
}

.our-team .title {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}

.our-team .post {
  font-size: 14px;
  color: #fff;
  line-height: 26px;
  text-transform: capitalize;
}

.our-team .social {
  padding: 0;
  margin: 40px 0 0 0;
  list-style: none;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 18px;
  color: #fff;
  margin: 0 7px;
  transition: all 0.5s ease 0s;
}

.our-team .social li a:hover {
  background: #fff;
  color: #00bed3;
}

@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }
}

.wrapper {
  width: 800px;
}

.devices {
  position: relative;
  height: 700px;
  overflow: hidden;
}

.desktop {
  position: absolute;
}

.desktop-iframe-container {
  width: 800px;
  height: 665px;
  background-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/121761/iMac-Flat-Mockup_1000px.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 800px;
  position: absolute;
  float: left;
  z-index: 100;
}
.visitor-label {
  font-size: 12px;
  color: rgba(253, 202, 8, 1) !important;
  margin-bottom: 0px !important;
  text-align: center;
}
#iframe-desktop {
  width: 100%;
  height: 1056px;
}

@media (max-width: 900px) {
  .wrapper {
    transform: scale(0.8, 0.8);
  }
}

@media (max-width: 700px) {
  .wrapper {
    transform: scale(0.6, 0.6);
  }
  #iframe-desktop {
    width: 300px;
  }
}

@media (max-width: 500px) {
  .wrapper {
    transform: scale(0.4, 0.4);
  }

  .devices {
    position: relative;
    height: 400px;
    overflow: visible !important;
    margin-left: -650px;
    margin-top: -100px !important;
  }
}

.card-1 {
  cursor: pointer !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.our-team-1 {
  text-align: center;
  position: relative;
  margin: 20px;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.15);
  border: 2px solid fff;
  transition: 0.6s ease 0s;
  border-radius: 10px;
}

.our-team-1:hover {
  text-align: center;
  position: relative;
  margin: 20px;
  box-shadow: 10px 10px rgb(252, 201, 7);
}

.our-team-1:before {
  content: "";
  position: absolute;
  border: 4px solid #f6511d !important;
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  opacity: 0;
  transform: scale(1.03);
  z-index: -1;
  transition: 0.6s ease 0s;
}

.our-team-1:hover:before {
  opacity: 1;
  transform: scale(1);
}

.our-team-1 .team-img {
  position: relative;
}

.our-team-1 .team-img img {
  height: 320px !important;
  border-radius: 10px;
}

.our-team-1 .team-img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.6s ease 0s;
}

.our-team-1:hover .team-img:before {
  opacity: 1;
  border-radius: 10px;
}

.our-team-1 .team-img img {
  width: 100%;
  height: auto;
}

.our-team-1 .team-content {
  padding: 30px 0 20px;
  position: relative;
  top: 0;
  transition: 0.6s ease 0s;
}

.our-team-1:hover .team-content {
  top: -50%;
}

.our-team-1 .team-content .name {
  color: #333;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  display: block;
  margin-bottom: 7px;
  text-transform: uppercase;
  transition: 0.6s ease 0s;
}

.our-team-1:hover .team-content .name {
  color: #fff;
}

.our-team-1 .team-content .post {
  color: #707070;
  font-size: 17px;
  font-weight: 500;
  display: block;
  text-transform: capitalize;
  transition: 0.6s ease 0s;
}

.our-team-1:hover .team-content .post {
  color: #ffb619;
  opacity: 0;
}

@-moz-document url-prefix() {
  .our-team-1:hover .team-content .post {
    color: #ffb619;
    opacity: 1;
  }
}

.our-team-1 .social {
  bottom: 0;
  font-size: 0;
  left: 0;
  margin: 0 0 30px;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  text-align: center;
  transform: scale(0);
  transition: 0.6s ease 0s;
}

.our-team-1:hover .social {
  opacity: 1;
  transform: scale(1);
}

.our-team-1 .social li {
  display: inline-block;
}

.our-team-1 .social li a {
  display: block;
  color: #333;
  font-size: 14px;
  padding: 0 15px;
  position: relative;
  transition: 0.3s ease 0s;
}

.our-team-1 .social li a:hover {
  color: #f6511d;
}

.our-team-1 .social li:last-child a:after {
  display: none;
}

@media screen and (max-width: 990px) {
  .our-team-1 {
    margin-bottom: 30px !important;
  }
}

table th {
  font-weight: normal !important;
}

.dwld {
  border-radius: 20px;
}

/* .MuiInput-root {
  width: 100%;
  border: 1px solid black !important;
} */
.clubImg {
  height: 320px;
  margin: 20px;
  object-fit: contain;
  border: 2px solid #f0f2f5 !important;
}

.text-bold {
  font-weight: bold !important;
}

.webinarPlayer {
  border: 10px solid black !important;
  border-radius: 10px;
}

.MuiInputBase-input::placeholder {
  font-size: 14px !important;
  color: #495057 !important;
}

.slider {
  height: 85vh;
}

.innerCover {
  height: 50vh;
}

.elearningCover {
  height: 55vh;
}

table tr {
  cursor: pointer;
}

.filledInfocard {
  min-height: 150px !important;
}

.webinarCard {
  height: 240px;
  object-fit: cover !important;
}

.course-desktop {
  display: block;
  padding: 3rem;
}

.course-mob {
  display: none;
}

.MuiInputBase-root::after {
  border-color: rgba(253, 202, 8, 1) !important;
}

/* Responsive Framework */
@media (max-width: 768px) {
  .slider {
    height: 320px;
  }
  .courseBg {
    background: url("../images/bg-11.png");
    padding-right: 24px;
    padding-bottom: 24px;
    background-size: cover;
    background-attachment: fixed;
  }
  .parent-c {
    position: relative;
    z-index: 1;
    padding-bottom: 10px !important;
  }
  .bg-blue {
    position: absolute;
    z-index: -1;
    top: 30%;
    left: 20%;
    display: none;
  }

  .congrats-img {
    display: none !important;
  }

  .innerCover {
    height: 240px !important;
  }

  .left-side2 {
    float: none !important;
    width: 100%;
    padding: 2%;
    margin-right: 15px;
  }

  .rules-discipline {
    text-align: justify;
  }

  .principal-img {
    width: 60%;
  }

  .staffImg {
    width: 100px !important;
    height: 120px !important;
  }

  .footer-logo {
    display: none !important;
  }

  .left-side1 {
    float: none;
    width: 100%;
    padding: 2%;
    margin-right: 15px;
  }

  .left-side {
    float: none;
    width: 100%;
    padding: 1%;
  }

  .naac-cover {
    display: none;
  }

  .contactMap {
    background: linear-gradient(270deg, transparent 1%, rgba(252, 201, 7, 0.65) 50%) !important;
    padding: 5px;
    border-radius: 5px;
    height: 240px;
  }

  .elearningCover {
    height: 15vh;
  }

  #root {
    overflow: hidden;
  }

  .desktop-logo {
    display: none;
  }

  .desktop-menu {
    display: none;
  }

  .testimonials-card .card-body {
    height: 100%;
  }

  .filledInfocard {
    min-height: 100% !important;
  }

  .address-container {
    text-align: center !important;
  }

  .webinarCard {
    height: 240px;
  }

  .heading-bg {
    font-size: 30px !important;
  }

  .course-desktop {
    display: none;
  }

  .course-mob {
    display: block;
    padding: 1rem;
  }

  .course-card {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    border: none !important;
    color: #fff !important;
    font-weight: 300;
    text-align: justify;
    background: url("../images/law-bg.jpg");
    background-size: cover;
    height: 100%;
  }

  .course-card h4 {
    font-size: calc(1.075rem + 0.3vw);
  }

  .congrats-slider {
    border-radius: 10px;
    opacity: 1;
    transition: opacity 1s, transform 1s;
    outline: 5px solid rgba(255, 255, 255, 0.3);
    outline-offset: -20px;
    width: 320px;
    height: 400px;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .MuiBox-root {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .MuiTypography-root.MuiTypography-button {
    font-size: 0.725rem;
  }
}

@media (max-width: 1080px) {
  .slider {
    height: 320px;
  }
}

@media only screen and (min-width: 1366px) and (orientation: landscape) {
  .main-head {
    font-size: 40px !important;
  }

  .address-container {
    font-size: 16px;
  }

  .MuiTypography-root.MuiTypography-button {
    font-size: 0.875rem;
  }
}

@media (max-width: 1398px) {
  .staffImg {
    width: 100px !important;
    height: 120px !important;
  }

  .desktop-iframe-container {
    transform: scale(0.65);
  }

  .MuiContainer-root {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 100% !important;
    position: relative;
    max-width: 1395px !important;
  }

  .congrats-slider {
    border-radius: 10px;
    opacity: 1;
    transition: opacity 1s, transform 1s;
    outline: 5px solid rgba(255, 255, 255, 0.3);
    outline-offset: -20px;
    width: 240px;
    height: 300px;
    object-fit: cover;
  }

  .achivements {
    border: 10px solid #f0f2f5;
    border-radius: 15px;
    height: 350px;
    width: 300px;
    object-fit: cover;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
}

@media (max-width: 1080px) {
  .congrats-slider {
    /* background: linear-gradient(360deg, transparent 1%, rgba(252, 201, 7, 0.65) 50%) !important; */
    /* padding: 20px; */
    border-radius: 10px;
    opacity: 1;
    transition: opacity 1s, transform 1s;
    outline: 5px solid rgba(255, 255, 255, 0.3);
    outline-offset: -20px;
    width: 320px;
    height: 380px;
    object-fit: cover;
  }

  .achivements {
    /* background: linear-gradient(104deg, #fcc907 4.81%, rgba(252, 201, 7, 0) 84.24%);
    padding: 15px; */
    border: 10px solid #f0f2f5;
    border-radius: 15px;
    height: 350px;
    width: 300px;
    object-fit: cover;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -ms-transition: opacity 0.5s ease-in-out;
    -o-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
}

/* .css-rtlcs5-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
} */
/* .ril__image {
  transform: scale(1.8) !important;
} */
.gallery-btn {
  /* position: absolute !important; */
  top: -50%;
  left: 30%;
}
.courses-card {
  border: 2px solid rgba(255, 255, 255, 0.2) !important;
  height: 150px !important;
  background: transparent !important;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
}
.courses-card h3 {
  color: #ffffff !important;
  font-size: 24px !important;
  font-weight: 700 !important;
}
.courses-card h6 {
  color: #ffffff !important;
  font-size: 16px !important;
  opacity: 0.6 !important;
}
.courses-card:hover {
  background: linear-gradient(
    121.85deg,
    rgba(10, 10, 10, 0.93) -22.99%,
    rgba(10, 10, 10, 0.45) 110.66%
  );
  border: 2px solid #ffffff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10.94px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px;
}
.card-custom {
  background: linear-gradient(107.89deg, #03aae9 -45.08%, rgba(3, 170, 233, 0) 108.56%);
  padding: 25px;
  display: flex;
  justify-content: center;
}
.card-custom:hover {
  background: linear-gradient(107.89deg, #03aae9 -45.08%, rgba(3, 170, 233, 0) 108.56%);
  padding: 25px;
  display: flex;
  justify-content: center;
}
.cust-img {
  margin: 0 auto !important;
  opacity: 1 !important;
}
.bg-blue {
  position: absolute;
  z-index: -1;
  top: 30%;
  left: 20%;
}
.parent-c {
  position: relative;
  z-index: 1;
  padding-bottom: 100px;
}

/* Iqac-new styles */
.common-box {
  display: flex;
}
@media (max-width: 768px) {
  .common-box {
    display: flex;
    flex-direction: column-reverse;
  }
}
.content-box {
  background-position: left top;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #ffffff;
  border-width: 2px 0 2px 0;
  border-color: rgba(10, 10, 10, 0.8);
  border-style: solid;
  box-shadow: 0px 25px 20px 0px rgba(61, 61, 61, 0.1);
  padding: 30px 30px 30px 30px;
  height: 100%;
}
/* .right-side-bar {
  background-position: left top;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  height: 100%;
  border-width: 2px 0 2px 0;
  border-color: rgba(10, 10, 10, 0.8);
  border-style: solid;
  box-shadow: 0px 25px 20px 0px rgba(61, 61, 61, 0.1);
  height: 100%;
} */
/* .iqac-header {
  border-bottom: 0.8px solid rgba(10, 10, 10, 0.8);
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
} */
.iqac-list {
  text-align: justify !important;
  font-size: 1rem !important;
  line-height: 1.985 !important;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  letter-spacing: -0.125px;
  font-family: "Raleway", sans-serif;
  font-weight: 500 !important;
}
.iqac-right-sidebar-links {
  text-align: justify !important;
  font-size: 0.85rem !important;
  line-height: 1.985 !important;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  letter-spacing: -0.125px;
  font-family: "Raleway", sans-serif;
  font-weight: 500 !important;
  list-style-type: none;
  padding-left: 5px;
  cursor: pointer;
}
.iqac-right-sidebar-links li {
  padding: 5px 0;
  transition: border-color 0.3s;
  margin: 5px 0;
  padding-inline-end: 12px;
}

.iqac-right-sidebar-links li:hover {
  color: rgba(252, 201, 7, 1);
  /* border-right: 3px solid rgba(252, 201, 7, 1); */
  font-weight: 600 !important;
}
/* .reports-box {
  background-color: #f2f3f5;
  padding: 20px 20px 20px 20px;
  text-align: center;
  border: 1px solid transparent; 
  transition: border-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 90px;
} */
.reports-box:hover {
  border-color: rgba(252, 201, 7, 1);
  background-color: #f2f3f580;
}
.iqac-download-link {
  text-align: center !important;
  font-size: 0.85rem !important;
  line-height: 1.985 !important;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  letter-spacing: -0.125px;
  font-family: "Raleway", sans-serif;
  font-weight: 500 !important;
  list-style-type: none;
  padding-left: 5px;
  cursor: pointer;
}
.reports-box-ssr {
  background-color: rgba(252, 201, 7, 1);
  text-align: center;
  padding: 10px;
  cursor: pointer;
}
.reports-box-ssr-content {
  color: #344767 !important;
}
.reports-box-ssr:hover {
  background-color: #344767;
}
.reports-box-ssr:hover > .reports-box-ssr-content {
  color: #f2f3f5 !important;
}
.action-reports-box {
  border: 2px solid #344767;
  padding: 10px;
  text-align: center;
}
.action-reports-box-content {
  font-size: 0.9rem !important;
  color: #344767;
}
.action-reports-box:hover {
  background-color: #344767;
}
.action-reports-box:hover > .action-reports-box-content {
  font-size: 0.9rem !important;
  color: #f2f3f5 !important;
}
.naac-reports-box {
  border-width: 0 0 0.5px 0;
  border-color: rgba(10, 10, 10, 0.5);
  border-style: solid;
  padding: 12px 0;
}
.naac-reports-box-content {
  font-size: 1rem !important;
  font-weight: 400 !important;
  cursor: pointer;
}
.naac-reports-box-content span {
  padding-right: 8px;
  font-size: 1.5rem !important;
}
.iqac-right-sidebar-links a {
  color: #344767;
  font-weight: 500;
}
.iqac-right-sidebar-links a:hover {
  color: rgba(252, 201, 7, 1) !important;
  font-weight: 500;
}
.active {
  color: rgba(252, 201, 7, 1) !important;
  font-weight: 500;
}
.iqac-breadcrumbs {
  background-color: #f2f3f5;
  border: 1px solid hsl(215, 15%, 92%);
  padding: 12px 5px;
  margin-bottom: 16px;
}
.iqac-breadcrumbs a {
  font-size: 14px !important;
}
.iqac-survey-img {
  width: 100%;
  height: 100%;
  max-height: 940px;
  border: 7.5px solid rgba(252, 201, 7, 1);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3), 0 0 6px 0 rgba(0, 0, 0, 0.18);
}
/* .quick-link-active {
  color: rgba(252, 201, 7, 1) !important;
  font-weight: 500;
  border-right: 3px solid rgba(252, 201, 7, 1);
  display: block;
  padding-inline-end: 12px;
} */
/* .iqac-title-left {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 24px;
} */
.common-box p,
ul,
ol {
  font-size: 16px;
  margin-top: 12px;
}
/* .left-content-area {
  padding: 32px 0 64px 0;
} */
.left-content-area h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 12px;
}
.left-content-area h3 {
  font-size: 16px;
  font-weight: 500;
}
.iqac-expand-menu button {
  border: none !important;
  cursor: pointer;
}
.iqac-expand-menu ul > li {
  list-style-type: none !important;
  font-size: 14px;
}
