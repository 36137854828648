.common-box {
  .iqacQuicklinks {
    //   background: rgba(3, 170, 233, 0.2);
    background-color: #cdeef7;
    box-shadow: -2px 2px 11px -1px rgba(3, 170, 233, 0.15);
    border-top: 8px solid #fffffa;
    padding: 32px 32px 32px 0;
    margin-top: -35%;
    .iqac-header {
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      color: $snlc-black;
      padding-inline-start: 32px;
      svg {
        display: block;
        width: 100%;
        margin-top: 8px;
      }
    }
    .iqac-right-sidebar-links {
      a {
        color: $snlc-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 22.4px */
        text-transform: capitalize;
        padding-inline-start: 32px;
        display: block;
        transition: font-weight 0.2s ease;
        &:hover {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%; /* 22.4px */
          text-transform: capitalize;
          color: $snlc-black !important;
          border-left: 5px solid $snlc-blue;
          display: block;
          padding-inline-start: 32px;
          white-space: wrap;
          text-align: left;
        }
      }
      .quick-link-active {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 22.4px */
        text-transform: capitalize;
        color: $snlc-black !important;
        border-left: 5px solid $snlc-blue;
        display: block;
        padding-inline-start: 32px;
        white-space: wrap;
        text-align: left;
      }
      .iqac-expand-menu {
        padding-left: 32px;
        button {
          background-color: transparent !important;
          color: $snlc-black;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 22.4px */
          text-transform: capitalize;
        }
        ul {
          padding-left: 0px !important;
        }
      }
    }
    .iqac-right-sidebar-links {
      padding-left: 0px !important;
    }
  }
  .left-content-area {
    padding: 46px 0;
    .iqac-title-left {
      color: $snlc-black;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      opacity: 0.8;
    }
    .heading-sideline {
      height: 1px;
      background-color: $snlc-yellow;
    }
    p,
    ol,
    ul {
      color: $snlc-black;
      text-align: justify;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
    ul > li {
      position: relative;
      list-style-type: none;
      margin: 0;
      margin-bottom: 1em;
      padding-left: 1.5em;
      &::after {
        content: "";
        height: 0.4em;
        width: 0.4em;
        background: $snlc-black;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 0.65em;
        left: -0.25rem;
      }
    }
    .reports-box {
      padding: 32px 20px;
      background-color: #03aae940;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      min-height: 120px;
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #03aae9;
        cursor: pointer;
        h3 {
          color: $snlc-white;
        }
        .downloadIcon {
          filter: invert(100%);
        }
      }
    }
    h3 {
      color: $snlc-black;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }
}
// Faculty UI
.facultyDetails {
  .facultyTabs {
    .MuiTab-root {
      padding: 12px 16px !important;
      &.Mui-selected {
        background-color: $snlc-blue !important;
        color: $snlc-black !important;
        font-weight: 600 !important;
      }
    }
  }
  .facultyList {
    font-weight: 600 !important;
    cursor: pointer;
    li {
      position: relative;
      display: table;
      margin: 12px 0;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        // background-color: $snlc-black;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        &::after {
          visibility: visible;
          transform: scaleX(1);
          color: $snlc-blue !important;
        }
        img {
          opacity: 1;
        }
      }
      img {
        opacity: 0.8;
      }
    }
  }
}

// profile List
.profileList {
  color: #344767 !important;
  cursor: pointer;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  letter-spacing: 1px;
  &:hover {
    letter-spacing: 1.5px;
  }
}

// committees UI
.committee-list {
  li {
    font-size: 20px;
    cursor: pointer;
    padding: 8px 0;
    text-transform: capitalize;
    cursor: pointer;
    transition: letter-spacing 0.3s;
    &:hover {
      letter-spacing: 1.2px;
      font-weight: 600;
    }
  }
}

.ppt-Card {
  background-color: transparent !important;
  img {
    max-height: 200px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    aspect-ratio: 3/2;
    object-position: center;
  }
  .ppt-details {
    max-height: 60px;
    a {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      line-height: 1.15;
      font-size: 16px !important;
    }
  }
}
.videocard {
  border-radius: 0px !important;
  .video-details {
    min-height: 100px;
    display: flex;
    align-items: center;
    .MuiTypography-h5 {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      line-height: 1.15;
      font-size: 16px !important;
    }
  }
}
.webinar-details {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    line-height: 1.15;
    font-size: 16px !important;
  }
}
