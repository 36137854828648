.bg-video-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 85vh;
  margin-bottom: 5%;
  //   background: url(https://designsupply-web.com/samplecontent/vender/codepen/20181014.png) no-repeat
  //     center center/cover;
  @media only screen and (max-width: 1080px) {
    height: auto;
    width: 100vw;
  }
  video {
    min-width: 100%;
    min-height: 80vh;
    z-index: 1;
    border: none !important;
    object-fit: cover !important;
    @media only screen and (max-width: 1080px) {
      height: 320px;
      min-height: unset;
    }
  }
  .overlay {
    width: 100%;
    height: 80vh;
    position: absolute;
    top: 0;
    left: 0;
    // background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.7) 50%);
    // background-size: 3px 3px;
    z-index: 2;
    // background-image: linear-gradient(
    //   0deg,
    //   rgba(10, 10, 10, 0.6) 39.51%,
    //   rgba(10, 10, 10, 0) 97.44%
    // );
    @media only screen and (max-width: 1080px) {
      height: 320px;
      min-height: unset;
    }
  }
  h1 {
    text-align: center;
    color: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    max-width: 40vw;
    width: 100%;
    height: 50px;
  }
  .announcement-wrapper {
    text-align: center;
    color: $snlc-black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 0 -5px auto;
    z-index: 3;
    max-width: 60vw;
    width: 100%;
    height: 180px;
    padding: 16px 2% 16px 10%;
    text-align: justify;
    background-color: $snlc-white;
    clip-path: polygon(15% 0, 100% 0, 100% 100%, 0% 100%);
    z-index: 99;
    @media only screen and (max-width: 1080px) {
      clip-path: none;
      max-width: 100vw;
      position: relative;
      height: 280px;
      padding: 16px;
    }
    .header {
      h2 {
        font-family: Georgia;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        color: $snlc-black;
        border-bottom: 1px solid #0a0a0a40;
        padding-block: 12px;
      }
    }
    .announcement-container {
      padding: 0 2%;
      div {
        position: relative;
        left: -3%;
        @media all and (min-width: 1600px) {
          left: -7%;
        }
        @media all and (max-width: 768px) {
          left: 0;
        }
      }
      .scroll-text {
        display: flex;
        padding-bottom: 8px;
        max-width: 50rem;
        justify-content: start;
        @media only screen and (max-width: 1080px) {
          max-width: 20rem;
        }
        p {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $snlc-black;
          text-align: start;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 117.8%; /* 18.848px */
        }
        a {
          text-align: justify;
          font-family: Arial;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 117.8%; /* 18.848px */
          color: $snlc-blue;
        }
      }
    }
  }
  .banner-links {
    background-color: #35bbed;
    margin-top: -10px;
    padding: 4px 2% 16px 2%;
    display: flex;
    align-items: center;
    .col-md-4 {
      border-right: 1px solid $snlc-white;
      text-align: center;

      &:last-child {
        border-right: none !important;
      }
      a {
        color: $snlc-black;
        font-family: Arial;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        text-transform: capitalize;
      }
    }
  }
}
.about-new {
  .header-area {
    display: flex;
    align-items: center;
    h2 {
      color: $snlc-black;
      font-family: Georgia;
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .content-area {
    display: flex;
    align-items: center;
    padding-bottom: 5%;
    p {
      color: $snlc-black;
      text-align: justify;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  @media all and (max-width: 768px) {
    // margin-top: 250px;
  }
}
