.slick-next {
  right: -30px !important;
}

.slick-prev {
  left: -55px !important;
}

.slick-dots {
  bottom: 5px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.courseBg {
  background: url("../images/bg-11.png");
  background-size: cover;
  padding-right: 24px;
  padding-bottom: 24px;
  /* background-image: url("../images/scale-1600_0.jpg"); */
  /* background-size: cover;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.65), rgba(255, 255, 255, 0.2)),
    url("../images/scale-1600_0.jpg"); */
}

/* .courseBg::before {
  opacity: 0.25;
} */
/* .courseimgBg {
  background: linear-gradient(77.91deg, #fcc907 -32.35%, rgba(252, 201, 7, 0) 101.19%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
} */
.css-xwfk3g {
  padding-top: 48px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 48px;
  opacity: 1;
  background: transparent;
  color: #344767;
  border-radius: none;
  box-shadow: none;
}

/* .css-incl6n-MuiTypography-root {
  font-size: size 0.65rem !important;
}
.css-bgea9c {
  margin-left: 0px !important;
  margin-right: 0px !important;
} */
.slick-prev:before {
  content: url("../images/previous.svg") !important;
}

.slick-next:before {
  content: url("../images/next.svg") !important;
}

hr.hr-1 {
  border: 0;
  height: 1.5px;
  background-image: linear-gradient(to right,
      rgba(10, 10, 10, 0),
      rgba(10, 10, 10, 0.75),
      rgba(10, 10, 10, 0));
  width: 75%;
  margin: 0 auto;
  margin-top: 10px;
}

.studyMaterialBg {
  background: linear-gradient(111.06deg, #03aae9 2.42%, #ffffff 151.3%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 2px -1px 9px #51a3c1;
  padding: 25px;
  border-top: #344767;
}

.iqacBg:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.webinarBg:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.studyMaterialBg:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.webinarBg {
  background: linear-gradient(149.76deg, #ff7a1d 39.33%, #ffffff 141.05%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 2px -1px 9px #d4863d;
  padding: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  background-color: #f0f2f5;
}

.iqacBg {
  background: linear-gradient(89.78deg, #ff7a1d -40.07%, #faf20a 127.73%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), inset 2px -1px 9px #efe75a;
  padding: 25px;
}

.downloadsBg {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  background-color: #f0f2f5;
}

.downloadsBg:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background-color: #ffffff;
}

.loader {
  padding-left: 650px;
  padding-top: 25px;
}

.loaderlogin {
  padding-left: 190px;
  padding-top: 10px;
}

/* .events-slider .slick-next {
  right: -25px !important;
} */
/* .events-slider.slick-prev {
  left: 15px !important;
} */

.blink {
  background-color: #1f1f21 !important;
  padding: 12px;
  color: #fff !important;
  border-radius: 12px;
  text-align: center !important;
}

.blink a {
  color: #fff !important;
}

.blink:hover {
  animation: none;
  -webkit-animation: none;
  cursor: pointer;
}

/* for Safari 4.0 - 8.0 */
@-webkit-keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.dynamic-button-disaled {
  pointer-events: none;
  /* Prevents user interactions */
  opacity: 0.5;
  /* Makes the div look disabled */
  /* Optionally change background color */
}

.footer-head-hover:hover {
  letter-spacing: 1.2px;
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 4px;
  border-bottom: 1px solid #afafaf;
}