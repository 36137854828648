.footerNew {
  h1 {
    color: $snlc-white;
    font-family: Georgia;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    @media only screen and (max-width: 768px) {
      font-size: 28px;
    }
  }
  .footerAddress {
    color: $snlc-white;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    opacity: 0.6;
    padding-top: 16px;
  }
  h2 {
    color: #fff;
    font-family: Georgia;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    opacity: 0.2;
    white-space: pre;
    word-break: break-all;
    text-align: right;
  }
  .footer-copyright {
   text-align: center;
  }
  .link-group {
    a {
      font-size: 14px;
      transition: opacity 0.3s ease;
      color: #fff;
      opacity: 0.8;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .right-section {
    h4 {
      color: #fffffa;
      font-family: Georgia;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
    }
    .contact-text {
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.4;
    }
    .cta-text {
      color: #fff;
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      opacity: 0.6;
    }
  }
  .position-heading{
    position: absolute;
    top: 10%;
    left: 65%;
    @media only screen and (max-width: 768px) {
      top: 5%;
      left: 10%;
    }
  }
  img{
    position: relative;
    top: 25%;
    @media only screen and (max-width: 768px) {
      // transform: scale(0.75);
      // left: -60%;
    }
  }
}
